import { Buffer } from 'buffer';

import { ErrorPage } from '@esg-monitor/components';
import type { LinksFunction } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError } from
'@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { typedjson } from 'remix-typedjson';

import stylesheet from './main.css';

globalThis.Buffer = Buffer;

export const links: LinksFunction = () => [
{ rel: 'stylesheet', href: stylesheet },
{ rel: 'preconnect', href: 'https://fonts.googleapis.com' },
{
  rel: 'preconnect',
  href: 'https://fonts.gstatic.com',
  crossOrigin: 'anonymous'
},
{
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap'
},
{
  rel: 'stylesheet',
  href: 'https://naver.github.io/egjs-flicking-plugins/release/latest/dist/pagination.css'
}];


export async function loader() {
  return typedjson({ ENV: { SENTRY_DSN: process.env.SENTRY_DSN } });
}

export default function App() {
  const data: {[key: string]: string;} = useLoaderData();
  return (
    <html>
      <head>
        <link rel="icon" href="data:image/x-icon;base64,AA" />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.ENV)}`
          }} />

        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  return <ErrorPage />;
}